<template lang="pug">
BasePane
  Accordion(fix)
    template(#title)
      span {{ $t('sizeAndSpacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    RangeInput(:label="$t('size')" v-model.number="size" :min="10" :max="400" :step="1")
    Margin
  hr.my-3
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      DeviceSelector,
      RangeInput,
      Margin,
      Hide,
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },
    computed: {
      ...mapState(['mobilePreview']),
      size: {
        get() {
          const mobileValue = this.getValueOf('mobile.height');
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf('desktop.height'));
          }

          return Number(this.getValueOf('$device.height'));
        },
        set(v) {
          this.setValueOf('$device.height', v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
    },
  };
</script>
